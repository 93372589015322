import React from 'react'

import Layout from "../components/layout"
import styles from '../templates/basic.module.less'
import { siteRedirects } from '../../content/redirect.js'

const NotFoundPage = ({ location }) => {
  // Generate a redirect if the current location is marked for redirection.
  const pathname = location.pathname;
  const redirectTarget = siteRedirects
    .map(({ from, to }) => from.exec(pathname) ? pathname.replace(from, to) : null)
    .filter(x => x != null)[0];

  if (redirectTarget) {
    return <meta http-equiv='refresh' content={`0; URL='${redirectTarget}'`} />;
  }

  return (
    <Layout>
      <div>
        <h1 className={styles.title}>NOT FOUND</h1>
        <p>The requested page was not found.</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
